import { Box, Button, Card, Link, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import UserAvatar from '../../UserAvatar';
import useAuth from '../../../hooks/useAuth';
import React from 'react';
import { FindUserView } from '../../../api/types/App/View/User';
import { TenantUserRoleType } from '../../../resources/TenantUser';
import simpleApiCall from '../../../hooks/simpleApiCall';
import { add } from '../../../api/endpoints/Api/Tenants/Users';
import { BaseUserCreateStep } from '../TenantUserCreateWizard';

interface UserFoundStepProps extends BaseUserCreateStep {
  user: FindUserView,
  roles: TenantUserRoleType[],
  onBack?: () => void;
}

const UserFoundStep = (
  {
    user,
    roles,
    onBack,
    userType,
    stepNumber,
  }: UserFoundStepProps,
) => {
  const { t } = useTranslation();
  const { currentDashboard } = useAuth();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const handleClick = () => {
    setSubmitting(true);
    simpleApiCall(
      add,
      {
        onSuccessToastMessage: '{{type}} "{{name}}" was added successfully!',
        onSuccessRedirectTo: () => '/tenant/users',
        translationParams: (response) => ({
          name: response.user.full_name,
          type: userType.charAt(0).toUpperCase() + userType.slice(1),
        }),
      },
    )({ tenantId: currentDashboard.id }, {}, {
      user_id: user.id,
      roles,
    });
  };

  return (
    <Card sx={{ p: 3 }}>
      <Typography
        color="textPrimary"
        variant="h6"
      >
        {`${stepNumber}. ${t('Existing {{type}} found', { type: userType })}`}
      </Typography>
      <Typography
        color={user.already_user ? 'error' : 'textSecondary'}
        variant="body1"
      >
        {user.already_user && t('This {{type}} is already member of this account.', { type: userType })}
        {!user.already_user && t('This {{type}} is already member of Baller. Do you want to add it to your account?', { type: userType })}
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Paper
          sx={{
            alignItems: 'center',
            display: 'flex',
            mb: 2,
            padding: 2,
          }}
          variant="outlined"
        >
          <UserAvatar user={user} size="large" noLink={!user.already_user} />
          <Box sx={{ ml: 2 }}>
            {user.already_user && (
              <Link
                color="textPrimary"
                component={RouterLink}
                to={`/users/${user.id}`}
                variant="subtitle2"
              >
                {user.full_name}
              </Link>
            )}
            {!user.already_user && (
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {user.full_name}
              </Typography>
            )}
            <Typography>
              <Link
                component="a"
                color="textSecondary"
                variant="body2"
                href={`mailto:${user.email}`}
              >
                {user.email}
              </Link>
            </Typography>
          </Box>
        </Paper>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mt: 6,
        }}
      >
        {onBack && (
          <Button
            color="primary"
            onClick={onBack}
            size="large"
            variant="text"
          >
            {t('Previous')}
          </Button>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {!user.already_user && (
          <Button
            onClick={handleClick}
            disabled={submitting}
            color="success"
            variant="contained"
          >
            {t('Add')}
          </Button>
        )}
      </Box>
    </Card>
  );
};

export default UserFoundStep;
