import { ListTenantTrackerRequestParams, CreateTenantTrackerRequestBody } from '../../../../types/App/Request/TenantTracker';
import { ListTenantTrackerView, FullTenantTrackerView } from '../../../../types/App/View/TenantTracker';
import { apiEndpoint, NoBody, StandardErrors, NoParams } from '../../../../client';
import { SuccessView } from '../../../../types/App/View';

export const list = apiEndpoint<ListTenantTrackerView, ListTenantTrackerRequestParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/trackers/');

export const remove = apiEndpoint<SuccessView, NoParams, NoBody, StandardErrors>('DELETE')('/api/tenants/{tenantId}/trackers/{id}');

export const show = apiEndpoint<FullTenantTrackerView, NoParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/trackers/{id}');

export const create = apiEndpoint<FullTenantTrackerView, NoParams, CreateTenantTrackerRequestBody, StandardErrors>('POST')('/api/tenants/{tenantId}/trackers');

export const update = apiEndpoint<FullTenantTrackerView, NoParams, CreateTenantTrackerRequestBody, StandardErrors>('PUT')('/api/tenants/{tenantId}/trackers/{id}');
