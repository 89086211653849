import { ListTenantsRequestParams, CreateTenantRequestBody, UpdateTenantRequestBody } from '../../../types/App/Request/Tenant';
import { ListTenantView, FullTenantView, TenantStatsView } from '../../../types/App/View/Tenant';
import { apiEndpoint, NoBody, StandardErrors, NoParams } from '../../../client';
import { SuccessView } from '../../../types/App/View';

export * as Contracts from './Contracts';

export * as Campaigns from './Campaigns';

export * as Participant from './Participant';

export * as Commissions from './Commissions';

export * as Commission from './Commission';

export * as Analytics from './Analytics';

export const list = apiEndpoint<ListTenantView, ListTenantsRequestParams, NoBody, StandardErrors>('GET')('/api/tenants/');

export const show = apiEndpoint<FullTenantView, NoParams, NoBody, StandardErrors>('GET')('/api/tenants/{id}');

export const stats = apiEndpoint<TenantStatsView, NoParams, NoBody, StandardErrors>('GET')('/api/tenants/{id}/stats');

export const create = apiEndpoint<FullTenantView, NoParams, CreateTenantRequestBody, StandardErrors>('POST')('/api/tenants');

export const update = apiEndpoint<FullTenantView, NoParams, UpdateTenantRequestBody, StandardErrors>('PUT')('/api/tenants/{id}');

export const remove = apiEndpoint<SuccessView, NoParams, NoBody, StandardErrors>('DELETE')('/api/tenants/{id}');

export * as Faq from './Faq';

export * as Tac from './Tac';

export * as Trackers from './Trackers';

export * as User from './User';

export * as Users from './Users';

export * as UserGroups from './UserGroups';
