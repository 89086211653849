import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Box } from '@material-ui/core';
import Scrollbar from '../components/Scrollbar';
import { DashboardType } from '../redux/slices/User';
import DashboardSelector from '../components/DashboardSelector';
import DashboardLayout from '../components/Dashboard';
import useAuth from '../hooks/useAuth';
import Loadable from '../components/Loadable';
import TenantDashboardPage from './Dashboard/TenantDashboard';
import TenantUserDashboardPage from './Dashboard/TenantUserDashboard';
import CategoriesPage from './Categories';
import Agencies from './Agencies';
import Affiliates from './Affiliates';
import AffiliateGroups from './AffiliateGroups';
import Contracts from './Contracts';
import ContractInvoices from './ContractInvoices';

const Analytics = Loadable(lazy(() => import('./Analytics')));
const Campaigns = Loadable(lazy(() => import('./Campaigns')));
const CampaignParticipant = Loadable(
  lazy(() => import('./CampaignParticipant'))
);
const TenantUsers = Loadable(lazy(() => import('./TenantUsers')));
const TenantTrackers = Loadable(lazy(() => import('./TenantTrackers')));
const UserAccountPage = Loadable(lazy(() => import('./User/UserAccount')));
const Clients = Loadable(lazy(() => import('./Clients')));
const ErrorNotFoundPage = Loadable(lazy(() => import('./ErrorNotFound')));
const Tenants = Loadable(lazy(() => import('./Tenants')));
const TenantUserGroups = Loadable(lazy(() => import('./TenantUserGroups')));
const Users = Loadable(lazy(() => import('./Users')));
const TermsAndConditions = Loadable(lazy(() => import('./TermsAndConditions')));
const FriendlyAskedQuestions = Loadable(
  lazy(() => import('./FriendlyAskedQuestions'))
);
const Commissions = Loadable(lazy(() => import('./Commissions')));
const CommissionInvoices = Loadable(lazy(() => import('./CommissionInvoices')));

const Dashboard = () => {
  const { currentDashboard } = useAuth();

  let campaign;
  let dashboard;
  switch (currentDashboard?.type ?? 'unknown') {
    case DashboardType.TenantAdmin:
      campaign = <Campaigns />;
      dashboard = <TenantDashboardPage />;
      break;

    case DashboardType.TenantUser:
      campaign = <CampaignParticipant />;
      dashboard = <TenantUserDashboardPage />;
      break;

    default:
      campaign = <Redirect to="/" />;
      dashboard = <Box />;
      break;
  }

  return (
    <DashboardSelector>
      <DashboardLayout>
        <Scrollbar>
          <Switch>
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            <Route exact path="/dashboard">
              {dashboard}
            </Route>
            <Route exact path="/dashboard/analytics">
              <Analytics />
            </Route>
            <Route path="/clients">
              <Clients />
            </Route>
            <Route path="/tenants">
              <Tenants />
            </Route>
            <Route path="/agencies">
              <Agencies />
            </Route>
            <Route path="/campaigns">{campaign}</Route>
            <Route path="/connections/contracts">
              <Contracts />
            </Route>
            <Route path="/connections/invoices">
              <ContractInvoices />
            </Route>
            <Route path="/tenant/users">
              <TenantUsers />
            </Route>
            <Route path="/affiliates">
              <Affiliates />
            </Route>
            <Route path="/tenant/user/groups">
              <TenantUserGroups />
            </Route>
            <Route path="/affiliate/groups">
              <AffiliateGroups />
            </Route>
            <Route path="/tenant/trackers">
              <TenantTrackers />
            </Route>
            <Route path="/tenant/tac">
              <TermsAndConditions />
            </Route>
            <Route path="/tenant/faq">
              <FriendlyAskedQuestions />
            </Route>
            <Route path="/users">
              <Users />
            </Route>
            <Route path="/commission/invoices">
              <CommissionInvoices />
            </Route>
            <Route path="/commissions">
              <Commissions />
            </Route>
            <Route path="/categories">
              <CategoriesPage />
            </Route>
            <Route path="/user/account">
              <UserAccountPage />
            </Route>
            <Route>
              <ErrorNotFoundPage insideDashboard />
            </Route>
          </Switch>
        </Scrollbar>
      </DashboardLayout>
    </DashboardSelector>
  );
};

export default Dashboard;
