import type { Function, O, Union } from 'ts-toolbelt';

type KeyValue = string | undefined | null;

type GetValue<
  F extends keyof T[number],
  T extends object[],
  K extends KeyValue | T[number][F],
  D = undefined,
  U extends T[number] = T[number]
> = K extends U[F] ? (U extends U ? (K extends U[F] ? U : never) : never) : D;

type GetFieldValue<
  UF extends string | symbol | number,
  F extends keyof T[number],
  T extends object[],
  K extends KeyValue | T[number][F],
  D = undefined,
  U extends T[number] = T[number]
> = K extends U[F]
  ? U extends U
    ? K extends U[F]
      ? UF extends keyof U
        ? U[UF]
        : D
      : never
    : never
  : D;

type GetFn<MV extends object, T extends MV[], Field extends keyof MV> = <
  K extends KeyValue,
  D extends T[number][Field] | undefined = undefined
>(
  value: K | T[number][Field],
  defValue?: D
) => GetValue<Field, T, K, GetValue<Field, T, D>>;

type FieldFn<
  MV extends object,
  T extends MV[],
  Field extends O.RequiredKeys<MV>
> = <
  MVF extends keyof MV,
  K extends KeyValue,
  D extends T[number][Field] | undefined = undefined
>(
  field: MVF,
  value: K | T[number][Field],
  defValue?: D | T[number][Field]
) => GetFieldValue<MVF, Field, T, K, GetFieldValue<MVF, Field, T, D>>;

type PluckFn<MV extends object, T extends MV[]> = <
  MVF extends keyof Union.Merge<T[number]>
>(
  field: MVF
) => Union.Merge<T[number]>[MVF][];

type SomeFn<
  MV extends object,
  T extends MV[],
  Field extends O.RequiredKeys<MV>
> = <K extends KeyValue[]>(
  ...values: K | Array<T[number][Field]>
) => K extends [] ? [] : GetValue<Field, T, K[number]>[];

type SmartList<
  MV extends object,
  T extends MV[],
  Field extends O.RequiredKeys<MV>
> = T & {
  get: GetFn<MV, T, Field>;
  field: FieldFn<MV, T, Field>;
  pluck: PluckFn<MV, T>;
  some: SomeFn<MV, T, Field>;
};

export const smartListBuilder = <MV extends object>() => ({
  setField:
    <Field extends O.RequiredKeys<MV>>(
      valueField: Field | O.RequiredKeys<MV>
    ) =>
    <T extends MV[]>(
      mapValues: Function.Narrow<T & MV[]>
    ): SmartList<MV, T, Field> => {
      const obj = mapValues as T;

      const getFn: GetFn<MV, T, Field> = (value, defValue?) => {
        const found = obj.find((val) => val[valueField as never] === value);

        if (!found && defValue !== undefined) {
          return getFn(defValue) as never;
        }

        return found as never;
      };

      const fieldFn: FieldFn<MV, T, Field> = (field, value, defValue?) => {
        const found = getFn(value, defValue);

        if (!found) {
          return undefined as never;
        }

        return found[field as never] ?? fieldFn(field, defValue);
      };

      const pluckFn: PluckFn<MV, T> = (field) =>
        Array.from(
          new Set(obj.map((item) => item[field as never]).filter((i) => !!i))
        ) as never;

      const someFn: SomeFn<MV, T, Field> = (...values) =>
        values.map((v) => getFn(v)) as never;

      return Object.assign(obj, {
        get: getFn,
        field: fieldFn,
        pluck: pluckFn,
        some: someFn,
      });
    },
});

export default smartListBuilder;
