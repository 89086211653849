import { ListParticipantCampaignsRequestParams, CampaignAnalyticsRequestParams } from '../../../../../types/App/Request/Campaign';
import { ListParticipantCampaignView, FullParticipantCampaignView } from '../../../../../types/App/View/Campaign/Participant';
import { apiEndpoint, NoBody, StandardErrors, NoParams } from '../../../../../client';
import { SuccessView } from '../../../../../types/App/View';
import { CampaignTriggersStatsView, CampaignLinksStatsView, CampaignAnalyticsView } from '../../../../../types/App/View/Campaign';

export const list = apiEndpoint<ListParticipantCampaignView, ListParticipantCampaignsRequestParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/participant/campaigns');

export const show = apiEndpoint<FullParticipantCampaignView, NoParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/participant/campaigns/{id}');

export const participate = apiEndpoint<SuccessView, NoParams, NoBody, StandardErrors>('PUT')('/api/tenants/{tenantId}/participant/campaigns/{id}/participate');

export const triggerStats = apiEndpoint<CampaignTriggersStatsView, NoParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/participant/campaigns/{id}/triggers/stats');

export const linkStats = apiEndpoint<CampaignLinksStatsView, NoParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/participant/campaigns/{id}/links/stats');

export const analytics = apiEndpoint<CampaignAnalyticsView, CampaignAnalyticsRequestParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/participant/campaigns/{id}/analytics');
