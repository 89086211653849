import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from '@material-ui/core';

interface LinearProgressWithLabelProps extends LinearProgressProps {
  value: number;
  hideLabel?: boolean;
}

const LinearProgressWithLabel = (props: LinearProgressWithLabelProps) => {
  const { hideLabel = false, ...rest } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          mr: 1,
        }}
      >
        <LinearProgress variant="determinate" {...rest} />
      </Box>
      {!hideLabel && (
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">
            {`${Math.round(rest.value)}%`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default LinearProgressWithLabel;
