import React from 'react';
import Page, { PageProps } from '../../../components/Page';
import ArrowLeft from '../../../icons/ArrowLeft';
import FormikForm from '../../../components/FormikForm';
import useAuth from '../../../hooks/useAuth';
import { Redirect } from 'react-router-dom';
import { DashboardType } from '../../../redux/slices/User';
import { create } from '../../../api/endpoints/Api/Tenants/Contracts';
import {
  CreateTenantContractFormLayout,
  CreateTenantContractFormMap,
} from '../../../resources/TenantContract';
import useLoadApiSelectorData from '../../../hooks/useLoadApiSelectorData';
import { tenantUserGroupSelector } from '../../../api/endpoints/Api/Tenants/UserGroups';

const ContractCreatePage = () => {
  const { currentDashboard } = useAuth();

  if (currentDashboard.type !== DashboardType.UserTenantAdmin) {
    return <Redirect to="/" />;
  }

  const { apiData: userGroups } = useLoadApiSelectorData(
    tenantUserGroupSelector
  )({ tenantId: currentDashboard.id });

  const pageProps: PageProps = {
    pageHeaderProps: {
      title: 'Create new contract',
      translationParams: {},
      actions: [
        {
          type: 'route_link',
          to: () => '/contracts',
          label: 'Cancel',
          icon: <ArrowLeft fontSize="small" />,
          variant: 'contained',
          color: 'secondary',
        },
      ],
      breadCrumbs: {
        items: [
          {
            label: 'Dashboard',
            to: '/dashboard',
          },
          {
            label: 'Contracts',
            to: '/contracts',
          },
        ],
      },
    },
    title: 'Create new contract',
  };

  return (
    <Page {...pageProps}>
      <FormikForm
        endpoint={create}
        formMap={CreateTenantContractFormMap()}
        formLayout={CreateTenantContractFormLayout(
          currentDashboard.tenant,
          userGroups
        )}
        context={{}}
        onSuccessMessage={'Contract "{{name}}" was created successfully!'}
        onSuccessMessageParams={(response) => ({
          name: response.data.title,
        })}
        onSuccessRedirectTo={(response) =>
          `/tenant/trackers/${response.data.id}`
        }
        routeParams={{ tenantId: currentDashboard.id }}
      />
    </Page>
  );
};
export default ContractCreatePage;
