import React from 'react';
import { Avatar, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { getAvatarColor } from '../utils/colors';
import getInitials from '../utils/getInitials';

const sizesMap = {
  mini: {
    size: 24,
    fontSize: '0.75rem',
  },
  normal: {
    size: 32,
    fontSize: '1rem',
  },
  large: {
    size: 40,
    fontSize: '1.25rem',
  },
  xlarge: {
    size: 100,
    fontSize: '3rem',
  },
};

export type User = {
  id: string,
  apc: string,
  full_name?: string,
  company_name?: string,
  title?: string,
  name?: string
};
type UserAvatarSizes = (keyof (typeof sizesMap));

const getName = (user: User) => user.full_name ?? user.company_name ?? user.name ?? user.title ?? '';

interface UserAvatarProp {
  user: User,
  noLink?: boolean,
  urlPattern?: string,
  size?: UserAvatarSizes,
}

const UserAvatar = (
  {
    user,
    urlPattern,
    size,
    noLink,
  }: UserAvatarProp,
) => {
  const url = urlPattern ?? '/users/:id';
  const s = sizesMap[size ?? 'normal'];

  let props = {};

  if (!noLink) {
    props = {
      component: Link,
      to: url.replace(':id', user.id),
    };
  }

  return (
    <Tooltip title={getName(user)}>
      <Avatar
        {...props}
        sx={{
          height: s.size,
          width: s.size,
          fontSize: s.fontSize,
          textDecoration: 'none',
          ...getAvatarColor(user.apc),
        }}
      >
        {getInitials(getName(user))}
      </Avatar>
    </Tooltip>
  );
};

export default UserAvatar;
