import React from 'react';
import {
  FormLayoutActions,
  FormLayoutArray,
  FormLayoutBox,
  FormLayoutButton,
  FormLayoutCard,
  FormLayoutColumn,
  FormLayoutComponent,
  FormLayoutElementTypes,
  FormLayoutField,
  FormLayoutFieldArray,
  FormLayoutFieldArrayErrors,
  FormLayoutFormButton,
  FormLayoutFormIconButton,
  FormLayoutGroup,
  FormLayoutNode,
  FormLayoutRow,
  WrappedContext,
} from '../types/FormLayoutType';
import {
  Alert,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Card,
  CardContent,
  CardHeader,
  Container,
  ContainerProps,
  Divider,
  Grid,
} from '@material-ui/core';
import { modifyProps, renderComponent } from '../types/RenderComponent';
import { parseCalcType } from '../types/CalculatedType';
import { FieldArray, FieldProps, getIn } from 'formik';
import FormField, { FormFieldProp } from './Form/FormField';
import Actions, { ActionsProp } from './Actions';
import FormButton, { FormButtonProps } from './FormButton';
import { formFieldDefinitionInitialValue } from '../types/FormTypes';
import i18next from 'i18next';
import FormIconButton, { FormIconButtonProps } from './FormIconButton';
/* eslint-disable @typescript-eslint/no-use-before-define */

type MUIGridProps = React.ComponentPropsWithoutRef<typeof Grid>;
type MUICardHeaderProps = React.ComponentPropsWithoutRef<typeof CardHeader>;
type MUICardProps = React.ComponentPropsWithoutRef<typeof Card>;
type MUICardContentProps = React.ComponentPropsWithoutRef<typeof CardContent>;

const renderFormLayoutCard = <Context extends any = any, >(
  element: FormLayoutCard<Context>,
  context: WrappedContext<Context>,
) => {
  let header;
  if (element.title !== undefined || element.header !== undefined || element.renderHeader !== undefined) {
    let headerProps: MUICardHeaderProps = {
      title: element.title,
    };

    headerProps = modifyProps(headerProps, parseCalcType(element.header, context));
    header = renderComponent(CardHeader, headerProps, element.renderHeader, context);
  }

  let contentProps: MUICardContentProps = {
    children: renderFormLayoutElements<Context>(parseCalcType(element.elements, context), context),
  };
  contentProps = modifyProps(contentProps, parseCalcType(element.cardContentProps, context));

  const content = renderComponent(CardContent, contentProps, element.renderCardContent, context);

  const bottomElements = parseCalcType(element.bottomElements, context);

  const children = (
    <>
      {header}
      {parseCalcType(element.headerDivider) === true && <Divider />}
      {content}
      {parseCalcType(element.bottomDivider) === true && <Divider />}
      {
        bottomElements !== undefined
        && bottomElements !== null
        && bottomElements.length > 0
        && (renderFormLayoutElements<Context>(parseCalcType(element.bottomElements, context), context))
      }
    </>
  );

  let cardProps: MUICardProps = {
    children,
    sx: {
      mb: 2,
      ...(parseCalcType(element.sx, context) ?? {}),
    },
  };
  cardProps = modifyProps(cardProps, parseCalcType(element.cardProps, context));

  let containerProps: ContainerProps = {
    maxWidth: parseCalcType(element.maxWidth, context) ?? false,
    disableGutters: true,
    children: renderComponent(Card, cardProps, element.renderCard, context),
  };

  containerProps = modifyProps(containerProps, parseCalcType(element.containerProps, context));

  return renderComponent(Container, containerProps, element.renderContainer, context);
};

const renderFormLayoutRow = <Context extends any = any, >(
  element: FormLayoutRow<Context>,
  context: WrappedContext<Context>,
) => {
  let gridProps: MUIGridProps = {
    container: true,
    sx: parseCalcType(element.sx, context),
    spacing: 3,
    children: renderFormLayoutElements<Context>(parseCalcType(element.elements, context), context),
  };

  gridProps = modifyProps(gridProps, parseCalcType(element.gridProps, context));

  return renderComponent(Grid, gridProps, element.renderGrid, context);
};

const renderFormLayoutColumn = <Context extends any = any, >(
  element: FormLayoutColumn<Context>,
  context: WrappedContext<Context>,
) => {
  let gridProps: MUIGridProps = {
    item: true,
    sx: parseCalcType(element.sx, context),
    lg: parseCalcType(element.lg, context),
    md: parseCalcType(element.md, context),
    sm: parseCalcType(element.sm, context),
    xl: parseCalcType(element.xl, context),
    xs: parseCalcType(element.xs, context),
    children: renderFormLayoutElements<Context>(parseCalcType(element.elements, context), context),
  };

  gridProps = modifyProps(gridProps, parseCalcType(element.gridProps, context));

  return renderComponent(Grid, gridProps, element.renderGrid, context);
};

const renderFormLayoutFieldArray = <Context extends any = any, >(
  element: FormLayoutFieldArray<any, Context>,
  context: WrappedContext<Context>,
) => {
  const field = parseCalcType(element.field, context);
  const initialValue = formFieldDefinitionInitialValue(field, context);

  return (
    <FieldArray
      name={field.name}
      render={(arrayHelpers) => renderFormLayoutElement(element.formLayout(arrayHelpers, initialValue), context)}
    />
  );
};

const renderFormLayoutArray = <Context extends any = any, >(
  element: FormLayoutArray<any, Context>,
  context: WrappedContext<Context>,
) => {
  const field = parseCalcType(element.field, context);
  const values = getIn(parseCalcType(element.values, context), field.name);

  return (
    <>
      {values.map((
        value,
        index,
      ) => renderFormLayoutElements<Context>(parseCalcType(element.arrayElements(index, value), context), context))}
    </>
  );
};

const renderFormLayoutFieldArrayErrors = <Context extends any = any, >(
  element: FormLayoutFieldArrayErrors<any, Context>,
  context: WrappedContext<Context>,
) => {
  const field = parseCalcType(element.field, context);
  let fieldErrors = getIn(parseCalcType(element.errors, context), field.name);
  const showError = getIn(context.formikContext.touched, field.name) && !!fieldErrors;

  if (typeof fieldErrors !== 'string') {
    return null;
  }

  fieldErrors = fieldErrors.replace(field.name, i18next.t(field.label, field.translationParams ?? {}));

  if (typeof element.formatString === 'function') {
    fieldErrors = element.formatString(fieldErrors);
  }

  if (showError !== true) {
    return null;
  }

  if (typeof element.renderError === 'function') {
    return element.renderError(fieldErrors);
  }

  return (
    <Box>
      <Alert severity="error" variant="outlined">
        {fieldErrors}
      </Alert>
    </Box>
  );
};

export const renderFormLayoutField = <Context extends any = any, >(
  element: FormLayoutField<any, Context>,
  context: WrappedContext<Context>,
) => {
  let fieldProps: Partial<FieldProps> = {};

  const field = parseCalcType(element.field, context);
  const namePrefix = parseCalcType(element.namePrefix, context);

  let { name } = field;
  if (namePrefix !== undefined && namePrefix !== null) {
    name = `${namePrefix}${name}`;
  }

  fieldProps = modifyProps(fieldProps, parseCalcType(field.formFieldProp, context));

  const formFieldProps: FormFieldProp<any> = {
    definition: {
      ...field,
      name,
    },
    context,
    ...fieldProps,
  };

  return renderComponent(FormField, formFieldProps, element.renderField, context);
};

const renderFormLayoutBox = <Context extends any = any, >(
  element: FormLayoutBox<Context>,
  context: WrappedContext<Context>,
) => {
  let boxProps: BoxProps = {
    sx: parseCalcType(element.sx, context),
    children: renderFormLayoutElements<Context>(parseCalcType(element.elements, context) ?? [], context),
  };

  boxProps = modifyProps(boxProps, parseCalcType(element.boxProps, context));

  return renderComponent(Box, boxProps, element.renderBox, context);
};

const renderFormLayoutComponent = <Context extends any = any, C extends React.ComponentType<React.ComponentProps<C>> = any, >(
  element: FormLayoutComponent<Context, C>,
  context: WrappedContext<Context>,
) => {
  let componentProps: React.ComponentProps<C> | { children: any } = {
    children: renderFormLayoutElements<Context>(parseCalcType(element.elements, context), context),
  };

  componentProps = modifyProps(componentProps, parseCalcType(element.componentProps, context));

  return renderComponent<{ children: any }>(element.component, componentProps, element.renderComponent, context);
};

const renderFormLayoutActions = <Context extends any = any, >(
  element: FormLayoutActions<Context>,
  context: WrappedContext<Context>,
) => {
  let actionsProps: ActionsProp<Context> = {
    type: parseCalcType(element.actionType, context),
    actions: parseCalcType(element.actions, context),
  };

  actionsProps = modifyProps(actionsProps, parseCalcType(element.actionsProps, context));

  return renderComponent(Actions, actionsProps, element.renderActions, context);
};

const renderFormLayoutButton = <Context extends any = any, >(
  element: FormLayoutButton<Context>,
  context: WrappedContext<Context>,
) => {
  let buttonProps: ButtonProps = {
    color: parseCalcType(element.color, context),
    variant: parseCalcType(element.variant, context),
    onClick: parseCalcType(element.onClick, context),
  };

  buttonProps = modifyProps(buttonProps, parseCalcType(element.buttonProps, context));

  return renderComponent(Button, buttonProps, element.renderButton, context);
};

const renderFormLayoutNode = <Context extends any = any, >(
  element: FormLayoutNode<Context>,
  context: WrappedContext<Context>,
) => parseCalcType(element.renderNode, context);

const renderFormLayoutFormButton = <Context extends any = any, >(
  element: FormLayoutFormButton<Context>,
  context: WrappedContext<Context>,
) => {
  let buttonProps: FormButtonProps = {
    color: parseCalcType(element.color, context),
    variant: parseCalcType(element.variant, context),
    children: parseCalcType(element.label, context),
    startIcon: parseCalcType(element.startIcon, context),
    actionDisabled: parseCalcType(element.actionDisabled, context),
  };

  buttonProps = modifyProps(buttonProps, parseCalcType(element.buttonProps, context));

  return renderComponent(FormButton, buttonProps, element.renderButton, context);
};
const renderFormLayoutFormIconButton = <Context extends any = any, >(
  element: FormLayoutFormIconButton<Context>,
  context: WrappedContext<Context>,
) => {
  let buttonProps: FormIconButtonProps = {
    color: parseCalcType(element.color, context),
    label: parseCalcType(element.label, context),
    icon: parseCalcType(element.icon, context),
    actionDisabled: parseCalcType(element.actionDisabled, context),
  };

  buttonProps = modifyProps(buttonProps, parseCalcType(element.buttonProps, context));

  return renderComponent(FormIconButton, buttonProps, element.renderButton, context);
};

const renderFormLayoutGroup = <Context extends any = any, >(
  element: FormLayoutGroup<Context>,
  context: WrappedContext<Context>,
) => renderFormLayoutElements<Context>(parseCalcType(element.elements, context), context);

export const renderFormLayoutElement = <Context extends any = any, >(
  element: FormLayoutElementTypes<Context>,
  context: WrappedContext<Context>,
) => {
  if (parseCalcType(element.hidden, context) === true) {
    return null;
  }

  switch (element.type) {
    case 'group':
      return renderFormLayoutGroup<Context>(element, context);
    case 'card':
      return renderFormLayoutCard<Context>(element, context);
    case 'row':
      return renderFormLayoutRow<Context>(element, context);
    case 'column':
      return renderFormLayoutColumn<Context>(element, context);
    case 'field':
      return renderFormLayoutField<Context>(element, context);
    case 'actions':
      return renderFormLayoutActions<Context>(element, context);
    case 'box':
      return renderFormLayoutBox<Context>(element, context);
    case 'component':
      return renderFormLayoutComponent<Context>(element, context);
    case 'button':
      return renderFormLayoutButton<Context>(element, context);
    case 'form_button':
      return renderFormLayoutFormButton<Context>(element, context);
    case 'form_icon_button':
      return renderFormLayoutFormIconButton<Context>(element, context);
    case 'field_array':
      return renderFormLayoutFieldArray<Context>(element, context);
    case 'field_array_errors':
      return renderFormLayoutFieldArrayErrors<Context>(element, context);
    case 'node':
      return renderFormLayoutNode<Context>(element, context);
    case 'array':
      return renderFormLayoutArray<Context>(element, context);
    default:
      return null;
  }
};

export const renderFormLayoutElements = <Context extends any = any, >(
  elements: FormLayoutElementTypes<Context>[],
  context: WrappedContext<Context>,
) => React.Children.toArray(
    (elements ?? []).map((element) => renderFormLayoutElement<Context>(element, context)),
  );

interface FormLayoutProp<Context = any> {
  layout: FormLayoutElementTypes<Context>,
  context: WrappedContext<Context>
}

const FormLayout = <Context extends any = any, >({
  layout,
  context,
}: FormLayoutProp<Context>) => (
  <>
    {renderFormLayoutElement(layout, context)}
  </>
  );

export default FormLayout;
