import React from 'react';
import Page, { PageProps } from '../../../components/Page';
import useAuth from '../../../hooks/useAuth';
import ListCommissionInvoices from '../../../components/CommissionInvoice/ListCommissionInvoices';

const ListTenantContractInvoicesPage = () => {
  const { currentDashboard } = useAuth();

  let listCommissionInvoices;
  if (currentDashboard.type === 'tenant_user') {
    listCommissionInvoices = (
      <ListCommissionInvoices
        type="tenant_user_commissions"
        tenantUser={currentDashboard.tenantUser}
      />
    );
  } else {
    listCommissionInvoices = (
      <ListCommissionInvoices
        type="tenant_commissions"
        tenantId={currentDashboard.id}
      />
    );
  }

  const pageProps: PageProps = {
    pageHeaderProps: {
      title: 'Connection Invoices',
      actions: [],
      breadCrumbs: {
        items: [
          {
            label: 'Dashboard',
            to: '/dashboard',
          },
          {
            label: 'Connection Invoices',
          },
        ],
      },
    },
    title: 'Connection Invoices',
  };

  return <Page {...pageProps}>{listCommissionInvoices}</Page>;
};

export default ListTenantContractInvoicesPage;
