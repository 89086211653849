import { useState } from 'react';
import UserTypeStep from './CreateWizard/UserTypeStep';
import { useTranslation } from 'react-i18next';
import EmailStep from './CreateWizard/EmailStep';
import useAuth from '../../hooks/useAuth';
import { toast } from 'react-hot-toast';
import UserFoundStep from './CreateWizard/UserFoundStep';
import ActionSelectStep from './CreateWizard/ActionSelectStep';
import InviteFormStep from './CreateWizard/InviteFormStep';
import CreateFormStep from './CreateWizard/CreateFormStep';
import { FindUserView } from '../../api/types/App/View/User';
import { find } from '../../api/endpoints/Api/Tenants/Users';
import { TenantUserRoleType } from '../../resources/TenantUser';
import { isResponseSuccess } from '../../api/client';

interface TenantUserCreateWizardProps {
  onlyRoles?: string[];
  userType: string,
  successUrl: string,
}

export interface BaseUserCreateStep {
  userType: string,
  stepNumber: string,
}

const TenantUserCreateWizard = ({ onlyRoles, userType, successUrl }: TenantUserCreateWizardProps) => {
  const { t } = useTranslation();

  let initialStep = 0;
  let initialRolesValue = null;

  if (onlyRoles && onlyRoles.length === 1) {
    initialStep = 1;
    initialRolesValue = onlyRoles;
  }

  const { currentDashboard } = useAuth();
  const [activeStep, setActiveStep] = useState<number>(initialStep);
  const [foundUser, setFoundUser] = useState<FindUserView>(null);
  const [formType, setFormType] = useState<string>(null);
  const [emailValue, setEmailValue] = useState<string>(null);
  const [rolesValue, setRolesValue] = useState<TenantUserRoleType[]>(initialRolesValue);

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleEmailCheck = async (email: string) => {
    setEmailValue(email);
    try {
      const result = await find({ tenantId: currentDashboard.id }, {}, { email });

      if (isResponseSuccess(result)) {
        setFoundUser(result.data);
        setActiveStep(2);
      } else if (result.code === 404) {
        setFoundUser(null);

        setActiveStep(2);
      }
    } catch (e) {
      setFoundUser(null);

      toast.error(t('Error occurred! Please try again later'));
    }
  };

  const handleUserType = (roles: TenantUserRoleType[]) => {
    setRolesValue(roles);
    setActiveStep(1);
  };

  const handleShowForm = (type: string): void => {
    setFormType(type);
    setActiveStep(3);
  };

  return (
    <div>
      {activeStep === 0 && (
        <UserTypeStep
          onNext={handleUserType} roles={onlyRoles}
          stepNumber={`${activeStep - initialStep + 1}`}
          userType={userType}
        />
      )}
      {activeStep === 1 && (
        <EmailStep
          onBack={initialStep === 0 ? handleBack : undefined}
          onNext={handleEmailCheck}
          stepNumber={`${activeStep - initialStep + 1}`}
          userType={userType}
        />
      )}
      {activeStep === 2 && foundUser !== null && (
        <UserFoundStep
          roles={rolesValue}
          user={foundUser}
          onBack={handleBack}
          stepNumber={`${activeStep - initialStep + 1}`}
          userType={userType}
        />
      )}
      {activeStep === 2 && foundUser === null && (
        <ActionSelectStep
          onBack={handleBack}
          onNext={handleShowForm}
          stepNumber={`${activeStep - initialStep + 1}`}
          userType={userType}
        />
      )}
      {activeStep === 3 && foundUser === null && formType === 'invite' && (
        <InviteFormStep
          roles={rolesValue}
          email={emailValue}
          onBack={handleBack}
          stepNumber={`${activeStep - initialStep + 1}`}
          userType={userType}
          successUrl={successUrl}
        />
      )}
      {activeStep === 3 && foundUser === null && formType === 'create' && (
        <CreateFormStep
          roles={rolesValue}
          email={emailValue}
          onBack={handleBack}
          stepNumber={`${activeStep - initialStep + 1}`}
          userType={userType}
          successUrl={successUrl}
        />
      )}
    </div>
  );
};

export default TenantUserCreateWizard;
