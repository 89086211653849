import { ListFriendlyAskedQuestionRequestParams, CreateFriendlyAskedQuestionRequestBody } from '../../../../types/App/Request/FriendlyAskedQuestion';
import { ListAdminFriendlyAskedQuestionView, ListFriendlyAskedQuestionView, FriendlyAskedQuestionView } from '../../../../types/App/View/FriendlyAskedQuestion';
import { apiEndpoint, NoBody, StandardErrors, NoParams } from '../../../../client';
import { SuccessView } from '../../../../types/App/View';

export const list = apiEndpoint<ListAdminFriendlyAskedQuestionView, ListFriendlyAskedQuestionRequestParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/faq/');

export const all = apiEndpoint<ListFriendlyAskedQuestionView, NoParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/faq/all');

export const remove = apiEndpoint<SuccessView, NoParams, NoBody, StandardErrors>('DELETE')('/api/tenants/{tenantId}/faq/{id}');

export const show = apiEndpoint<FriendlyAskedQuestionView, NoParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/faq/{id}');

export const create = apiEndpoint<FriendlyAskedQuestionView, NoParams, CreateFriendlyAskedQuestionRequestBody, StandardErrors>('POST')('/api/tenants/{tenantId}/faq');

export const update = apiEndpoint<FriendlyAskedQuestionView, NoParams, CreateFriendlyAskedQuestionRequestBody, StandardErrors>('PUT')('/api/tenants/{tenantId}/faq/{id}');
