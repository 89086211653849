import React from 'react';
import { Field, getIn, useFormikContext } from 'formik';
import { TextField as TextFieldMano } from './TextField';
import importTranslation from '../../hooks/ut';
import {
  FormFieldDefinition,
  FormFieldDefinitionAny,
  FormFieldDefinitionContextType,
} from '../../types/FormTypes';
import { parseCalcType } from '../../types/CalculatedType';
import {
  AutocompleteRenderInputParams,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { Select, Switch } from 'formik-material-ui';
import { MapValue } from '../../types/MapValues';
import { Autocomplete } from './Autocomplete';
import { QuillField } from './QuillField';
import { CheckboxWithLabel } from './CheckboxWithLabel';
import { fromUnixTime, getUnixTime } from 'date-fns';
import { MobileDateTimePicker } from '@material-ui/lab';

export interface FormFieldProp<T extends FormFieldDefinitionAny, C = FormFieldDefinitionContextType<T>> extends React.ComponentPropsWithoutRef<typeof Field> {
  definition: T,
  context: C,
}

const FormField = <T extends FormFieldDefinition<any, any>, >(props: FormFieldProp<T>) => {
  const { t } = importTranslation();
  const formik = useFormikContext();
  const {
    definition,
    context,
    name = definition.name,
    label = definition.label,
    leftLabel = definition.leftLabel,
    type = definition.type ?? 'text',
    component = TextFieldMano,
    margin = 'normal',
    variant = 'outlined',
    fullWidth = true,
    disabled,
    onBlur,
    ...other
  } = props;

  definition.listValues ??= [];
  definition.translationParams ??= {};

  const helperText = parseCalcType(definition.helperText, context);
  const fieldError = getIn(formik.errors, name);
  const showError = getIn(formik.touched, name) && !!fieldError;

  let calcDisabled = disabled;
  if (typeof definition.disabled === 'function') {
    calcDisabled = definition.disabled(context, formik.values);
  }

  let calcHidden;
  if (typeof definition.hidden === 'function') {
    calcHidden = definition.hidden(context, formik.values);
  }

  if (calcHidden) {
    return null;
  }

  const dontTrans = parseCalcType(definition.dontTranslate, context) ?? false;
  const dontRenderLabel = parseCalcType(definition.dontRenderLabel, context) ?? false;

  const renderField = () => {
    switch (type) {
      case 'list':
        return (
          <FormControl
            fullWidth={fullWidth}
            variant={variant}
            error={showError}
            margin="normal"
            disabled={calcDisabled ?? formik.isSubmitting}
          >
            <InputLabel>{t(label, definition.translationParams)}</InputLabel>
            <Field
              component={Select}
              name={name}
              type={type}
              label={t(label, definition.translationParams)}
              variant={variant}
              fullWidth={fullWidth}
              {...other}
              {...(parseCalcType(definition.formFieldProp, context) ?? {})}
              disabled={calcDisabled ?? formik.isSubmitting}
            >
              {definition.listValues.map((mapValue) => {
                let optionLabel;

                if (dontTrans) {
                  optionLabel = mapValue.label.replaceAll(' ', '\u00A0');
                } else {
                  optionLabel = t(mapValue.label, definition.translationParams);
                }

                return (
                  <MenuItem
                    key={mapValue.value} value={mapValue.value}
                  >
                    {optionLabel}
                  </MenuItem>
                );
              })}
            </Field>
            <FormHelperText>
              {t(showError ? fieldError.replace(name, label) : helperText, definition.translationParams)}
            </FormHelperText>
          </FormControl>
        );
      case 'select':
        return (
          <FormControl
            fullWidth={fullWidth}
            variant={variant}
            margin="normal"
            error={showError}
            disabled={calcDisabled ?? formik.isSubmitting}
          >
            <InputLabel>{t(label, definition.translationParams)}</InputLabel>
            <Field
              component={Select}
              name={name}
              type={type}
              label={t(label, definition.translationParams)}
              variant={variant}
              fullWidth={fullWidth}
              {...other}
              {...(parseCalcType(definition.formFieldProp, context) ?? {})}
              disabled={calcDisabled ?? formik.isSubmitting}
            >
              {definition.listValues.map((mapValue) => {
                let optionLabel;

                if (dontTrans) {
                  optionLabel = mapValue.label.replaceAll(' ', '\u00A0');
                } else {
                  optionLabel = t(mapValue.label, definition.translationParams);
                }

                return (
                  <option
                    key={mapValue.value} value={mapValue.value}
                  >
                    {optionLabel}
                  </option>
                );
              })}
            </Field>
            <FormHelperText>
              {t(showError ? fieldError.replace(name, label) : helperText, definition.translationParams)}
            </FormHelperText>
          </FormControl>
        );

      case 'autocomplete':
        return (
          <Field
            component={Autocomplete}
            name={name}
            options={definition.listValues}
            getOptionLabel={(option: MapValue) => option.label ?? option}
            isOptionEqualToValue={(
              option: MapValue,
              value: MapValue,
            ) => option.value === value.value}
            label={t(label, definition.translationParams)}
            variant={variant}
            fullWidth={fullWidth}
            groupBy={definition.groupBy}
            noOptionsText={t('No matches')}
            disabled={calcDisabled ?? formik.isSubmitting}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField
                {...params}
                error={showError}
                margin={margin}
                helperText={t(showError ? (fieldError?.value ?? fieldError).replace(name, label) : helperText, definition.translationParams)}
                label={t(label)}
                variant={variant}
                disabled={calcDisabled ?? formik.isSubmitting}
              />
            )}
            {...other}
            {...(parseCalcType(definition.formFieldProp, context) ?? {})}
          />
        );

      case 'switch':
        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {leftLabel !== undefined && (
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {t(leftLabel, definition.translationParams)}
              </Typography>
            )}
            <Field
              component={Switch}
              name={name}
              type="checkbox"
              margin={margin}
              {...other}
              {...(parseCalcType(definition.formFieldProp, context) ?? {})}
              disabled={calcDisabled ?? formik.isSubmitting}
            />
            {dontRenderLabel === false && (
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {t(label, definition.translationParams)}
              </Typography>
            )}
          </Box>
        );

      case 'checkboxes':
        return definition.listValues.map((listValue) => (
          <Field
            key={listValue.value}
            component={CheckboxWithLabel}
            type="checkbox"
            name={name}
            value={listValue.value}
            Label={{ label: listValue.label }}
            {...other}
            {...(parseCalcType(definition.formFieldProp, context) ?? {})}
            disabled={calcDisabled ?? formik.isSubmitting}
          />
        ));

      case 'textarea':
        return (
          <Field
            component={TextFieldMano}
            name={name}
            type="text"
            margin={margin}
            label={t(label, definition.translationParams)}
            variant={variant}
            fullWidth={fullWidth}
            helperText={helperText}
            multiline
            minRows={2}
            {...other}
            {...(parseCalcType(definition.formFieldProp, context) ?? {})}
            disabled={calcDisabled ?? formik.isSubmitting}
          />
        );

      case 'quill':
        return (
          <Field
            component={QuillField}
            name={name}
            label={t(label, definition.translationParams)}
            fullWidth={fullWidth}
            helperText={helperText}
            margin={margin}
            {...other}
            {...(parseCalcType(definition.formFieldProp, context) ?? {})}
            disabled={calcDisabled ?? formik.isSubmitting}
          />
        );

      case 'timestamp':
        return (
          <MobileDateTimePicker
            onChange={(newDate) => {
              formik.setFieldValue(name, getUnixTime(newDate));
            }}
            label={t(label, definition.translationParams)}
            renderInput={(inputProps) => (
              <Field
                component={TextFieldMano}
                helperText={helperText}
                name={name}
                type={type}
                margin={margin}
                variant={variant}
                fullWidth={fullWidth}
                {...inputProps}
                {...other}
                {...(parseCalcType(definition.formFieldProp, context) ?? {})}
                disabled={calcDisabled ?? formik.isSubmitting}
              />
            )}
            ampm={false}
            value={fromUnixTime(getIn(formik.values, name))}
          />
        );

      default:
        return (
          <Field
            component={component}
            name={name}
            type={type}
            margin={margin}
            label={t(label, definition.translationParams)}
            variant={variant}
            helperText={helperText}
            fullWidth={fullWidth}
            {...other}
            {...(parseCalcType(definition.formFieldProp, context) ?? {})}
            disabled={calcDisabled ?? formik.isSubmitting}
          />
        );
    }
  };

  return (
    <>
      {definition.textPrimary && (
        <Typography
          color="textPrimary"
          variant="subtitle2"
        >
          {t(parseCalcType(definition.textPrimary, context), definition.translationParams)}
        </Typography>
      )}
      {definition.textSecondary && (
        <Typography
          color="textSecondary"
          sx={{ py: 1 }}
          variant="body2"
        >
          {t(parseCalcType(definition.textSecondary, context), definition.translationParams)}
        </Typography>
      )}
      {renderField()}
    </>
  );
};

export default FormField;
