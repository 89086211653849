import React from 'react';
import Page, { PageProps } from '../../../components/Page';
import useLoadApiData from '../../../hooks/useLoadApiData';
import { useParams } from 'react-router';
import useAuth from '../../../hooks/useAuth';
import { Redirect } from 'react-router-dom';
import {
  paid,
  show,
} from '../../../api/endpoints/Api/Tenants/Commission/Invoices';
import { FullCommissionInvoiceView } from '../../../api/types/App/View/CommissionInvoice';
import ArrowLeft from '../../../icons/ArrowLeft';
import FormikForm from '../../../components/FormikForm';
import {
  MarkAsPaidFormLayout,
  MarkAsPaidFormMap,
} from '../../../resources/CommissionInvoice';

const MarkAsPaidPage = () => {
  const { currentDashboard } = useAuth();

  if (currentDashboard.type !== 'tenant_admin') {
    return <Redirect to="/" />;
  }

  const { id } = useParams<{ id: string }>();
  const { apiData } = useLoadApiData(show)({
    tenantId: currentDashboard.id,
    id,
  });

  if (apiData === null) {
    return null;
  }

  const pageProps: PageProps<FullCommissionInvoiceView> = {
    pageHeaderProps: {
      title: 'Mark As Paid Invoice #{{number}}',
      translationParams: {
        number: apiData.invoice.number,
      },
      actions: [
        {
          type: 'route_link',
          to: () => `/connections/invoices/${id}`,
          label: 'Cancel',
          icon: <ArrowLeft fontSize="small" />,
          variant: 'contained',
          color: 'secondary',
        },
      ],
      breadCrumbs: {
        items: [
          {
            label: 'Dashboard',
            to: '/dashboard',
          },
          {
            label: 'Connection Invoices',
            to: '/connections/invoices',
          },
          {
            label: 'Connection Invoice #{{number}}',
            translationParams: {
              number: apiData.invoice.number,
            },
            to: `/connections/invoices/${apiData.id}`,
          },
          {
            label: 'Mark As Paid',
          },
        ],
      },
    },
    title: 'Mark As Paid Invoice #{{number}}',
    titleProps: {
      number: apiData.invoice.number,
    },
    context: apiData,
    compactWidth: 'md',
  };

  return (
    <Page {...pageProps}>
      <FormikForm
        endpoint={paid}
        formMap={MarkAsPaidFormMap}
        formLayout={MarkAsPaidFormLayout}
        context={{}}
        routeParams={{ id, tenantId: apiData.commission.tenant.id }}
        onSuccessMessage={`Invoice ${apiData.invoice.number} was marked as paid successfully!`}
        onSuccessRedirectTo={() => `/connections/invoices/${apiData.id}`}
      />
    </Page>
  );
};

export default MarkAsPaidPage;
